// @flow

import React, { useState, useContext } from 'react';
import Helmet from 'react-helmet';
import { Lframe } from '@latitude/lframe';
import { Hero } from '@latitude/hero';
import Section from '@latitude/section';
import { Heading4 } from '@latitude/heading';
import { Text } from '@latitude/text';
import { StickyNavigation } from '@latitude/sticky-navigation';
import {
  BREAKPOINT,
  COLOR,
  ALIGN,
  MARGIN
} from '@latitude/core/utils/constants';
import Layout from '../../components/layout';
import MobileAppInstallPrompts from '@latitude/mobile-app-install-prompt/MobileAppInstallPrompt';
import PageData from '../../data/pages/our-company.json';
import { PageContext } from '@/context/PageContext';
import imgHero from '../../images/lframe/L-shape-about-us.png';
import HeroBanner from '@/components/lab-components/HeroBanner';

const OurCompanyPage = () => {
  const [isNavSticky, setIsNavSticky] = useState(false);

  /** Contentful Data */
  const contentfulPageData = useContext(PageContext);
  const state = contentfulPageData?.updatedBody || {};
  /** - END - */

  return (
    <Layout>
      <MobileAppInstallPrompts />

      <main className="navigation-spacer">
        <Helmet>
          <link
            rel="canonical"
            href="https://www.gemfinance.co.nz/about-us/our-company/"
          />
          <title>Our Company | Gem by Latitude</title>
          <meta
            name="description"
            content="Latitude is a leading digital instalments and lending business in Australia and New Zealand. Get in touch today. "
          />
        </Helmet>

        {state?.heroBannerData?.[0] ? (
          <HeroBanner {...state?.heroBannerData[0]} />
        ) : (
          <>
            <Lframe bgImage={imgHero} />
            <Hero
              title={PageData.content.hero.title}
              text={PageData.content.hero.text}
              css={`
                background: ${COLOR.GREY6};
                @media (min-width: ${BREAKPOINT.MD}) {
                  .HeroContent {
                    height: 420px;
                  }
                }
              `}
            />
          </>
        )}

        <div className="d-block">
          <StickyNavigation
            items={PageData.nav}
            isSticky={isNavSticky}
            onStickyNavStateChange={setIsNavSticky}
            offsetElem="[data-sticky-navigation-offset]"
            {...state?.inPageNavData?.[0]}
          />
        </div>

        <WhoAreWeSection />
      </main>
    </Layout>
  );
};

const WhoAreWeSection = () => (
  <Section id="who-are-we" className="bg-white">
    <div className="row justify-content-center">
      <div className="col-12 col-sm-8 text-center">
        <Heading4
          align={ALIGN.CENTER}
          color={COLOR.BLACK}
          marginTop={MARGIN.M24}
          marginBottom={MARGIN.M8}
        >
          Who are we?
        </Heading4>
        <Text
          align={ALIGN.LEFT}
          color={COLOR.BLACK}
          marginTop={MARGIN.M32}
          marginBottom={MARGIN.M24}
        >
          When it comes to your personal finances, we’re obsessed (in a good
          way!).
          <br />
          <br />
          Our smart range of products include personal loans, insurance, credit
          cards, interest free and promotional retail finance. So whether you’re
          renovating your home, long overdue a holiday, looking to consolidate
          your debt or in need of an everyday credit card, think Gem.
          <br />
          <br />
          You may already know us for Gem Visa, but our other products have now
          joined the Gem family, including Gem Personal Loans and Gem
          CreditLine.
          <br />
          <br />
          We’re part of Latitude Financial Services, which looks after more than
          2.5 million customers across New Zealand and Australia and has forged
          solid, long term partnerships with some of the region’s most iconic
          and successful retailers.
          <br />
          <br />
          Get in touch today
          <br />
          <br />
          Whether you want to enquire about one of our products, find out how we
          can help your business grow, or even discuss your existing
          relationship with us, our team is on hand and ready to help.
        </Text>
      </div>
    </div>
  </Section>
);

export default OurCompanyPage;
